<template>
  <div class="p-grid">
    <Card class="style p-col">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0">
              <strong>{{ _t("EXPORT") }}</strong>
            </h3>
          </div>
        </div>
      </template>
      <template #content>
        <div
            class="p-d-flex p-flex-row p-flex-wrap"
            style="width: 320px; gap: 20px"
        >
          <Button
              v-for="(button, index) of buttons"
              :key="index"
              :label="button.label"
              :loading="button.isLoading"
              class="p-jc-center p-ai-center p-button-success buttonImportExport"
              icon="pi pi-download"
              @click="download(button.type)"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import {
  getAlarmPointsExport,
  getOrganizationsExport,
  getParticipantGroupsExport,
  getParticipantsExport,
} from "@/api/export";

export default {
  data: () => ({
    buttons: [],
    alarmpoints: "",
    loading: false,
    loadingType: "alarmpoints",
  }),
  mounted() {
    this.buttons = [
      {
        label: this._t("label_alarmpoints"),
        isLoading: this.loading && this.loadingType === "alarmpoints",
        type: "alarmpoints",
      },
      {
        label: this._t("label_audit_report_change_log_org_filter"),
        isLoading: this.loading && this.loadingType === "organizations",
        type: "organizations",
      },
      {
        label: this._t("label_participantGroups"),
        isLoading: this.loading && this.loadingType === "participant-groups",
        type: "participant-groups",
      },
      {
        label: this._t("label_participants"),
        isLoading: this.loading && this.loadingType === "participants",
        type: "participants",
      },
    ];
  },
  methods: {
    getApiMethod(type) {
      switch (type) {
        case "alarmpoints":
          return getAlarmPointsExport();
        case "organizations":
          return getOrganizationsExport();
        case "participant-groups":
          return getParticipantGroupsExport();
        case "participants":
          return getParticipantsExport();
        default:
          return getAlarmPointsExport();
      }
    },
    download(type) {
      this.loading = true;
      this.loadingType = type;
      return this.getApiMethod(type)
          .then(
              (res) => {
                if (res.status === 200) {
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  const blob = new Blob([res.data], {type: "xlsx"});
                  var url = window.URL.createObjectURL(blob);
                  a.href = url;
                  let headerLine = res.headers["content-disposition"];
                  a.download = `${Date.now()}.xlsx`;
                  if (headerLine) {
                    a.download = headerLine.split("=").pop().replace('"', "");
                  }
                  a.click();
                  window.URL.revokeObjectURL(url);
                  this.loading = false;
                }
                return Promise.reject();
              },
              (error) => {
                let data = error.response?.data
                    ? error.response.data instanceof Object
                        ? error.response.data
                        : JSON.parse(error.response.data)
                    : {};
                this.$toast.add({
                  severity: "error",
                  summary: this._t("label_error"),
                  detail: data.reason ? ": " + data.reason : data.message,
                  life: 3000,
                });
              }
          )
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>
.buttonImportExport {
  width: 170px;
  height: 50px;
}
</style>
